import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import Spinner from "../../../components/spinner";
import Errors from "../../errors";
import Pagination from "../../../components/elements/pagination";
import useGetCategoryList from "../../../hooks/category/useGetCategoryList";
import { RiDeleteBinLine } from "react-icons/ri";
import React, { useRef, useState } from "react";
import DeleteModal from "../../../components/elements/DeleteModal";
import useDeleteCategory from "../../../hooks/category/useDeleteCategory";
import useDownload from "../../../hooks/useDownload";
import toast from "react-hot-toast";
import { FiUpload, FiX } from "react-icons/fi";
import useGetProductUploadList from "../../../hooks/productupload/useGetProductUploadList";
import useUploadfile from "../../../hooks/useUploadfile";
import ConfirmationModal from "../../../components/elements/ConfirmationModal";
const ProductUpload = () => {
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const uploadRef = useRef(null);
  const { setDataToServer } = useUploadfile({
    // pass necessary options
    onSuccess: (data) => {
      console.log("Upload successful", data);
  },
  onError: (error) => {
      console.error("Error uploading file:", error);
  }
  });

  const [fileName, setFileName] = useState("");
  const showFileName = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setFileToUpload(file); // Store the file to be uploaded
      setUploadModalOpen(true); // Open the modal
    } else {
      setFileName("");
      setFileToUpload(null);
    }
  };

  const clearFileName = () => {
    setFileName("");
    setFileToUpload(null);
    if (uploadRef.current) {
      uploadRef.current.value = "";
    }
  };
  const {
    data,
    handlePageClick,
    handleSearchVal,
    paramsObject,
    handleSetLimit,
  } = useGetProductUploadList();
  const [category, setCategory] = useState(data.data?.data || []);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleUploadConfirm = () => {
    if (fileToUpload) {
      setDataToServer.mutate({ excel_file: fileToUpload });
    }
    setModalOpen(false); // Close modal after confirmation
  };

  const handleDelete = (id) => {
    setCategory(id);
    setDeleteModalOpen(true);
  };
  const { downloadFile: downloadFileHook } = useDownload({
    url: "product_excel_upload_download", // Your download URL
    onSuccess: () => toast.success("File downloaded successfully"),
    onError: (err) => toast.error("No product exist for this category"),
  });

  const handleDownload = (id) => {
    downloadFileHook(id);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      setFileToUpload(file);
      setModalOpen(true); // Open confirmation modal
      e.target.value = "";
    } else {
      setFileName("");
      setFileToUpload(null);
    }
  };

  // const { handleSubmit, setDataToServer } = useDeleteCategory();
  return (
    <>
      <div className="category px-4">
        <div className="category__head headingBorder__b">
          <h2 className="titleHeading">Uploaded Products</h2>
        </div>
        <div className="category_table py-5 space-y-5">
          <div className="flex items-end justify-end space-x-4">
            <div class="flex flex-col justify-center items-center underline-offset-1 underline gap-2">
              <button
                className="flex gap-2 items-center "
                // onSubmit={handleSubmit(handleFileUpload)}
                type="submit"
                onClick={() => uploadRef.current.click()}
              >
                <FiUpload />
                Upload
              </button>
              {/* <label for="fileInput">Upload File</label> */}
              <input
                ref={uploadRef}
                onChange={handleFileChange}
                type="file"
                accept=".xls, .xlsx"
                id="fileInput"
                className="hidden"
              />
            </div>
            {/* {fileName && (
              <div className="flex gap-2 items-center">
                <p>{fileName}</p>
                <button onClick={clearFileName}>
                  <FiX/>
                </button>
              </div>
            )} */}
            <div>
              <label htmlFor="" className="label">
                Limit
              </label>
              <select
                name="limit"
                value={data.data?.per_page}
                className="input"
                onChange={handleSetLimit}
              >
                {Array(5)
                  .fill(1)
                  .map((_, idx) => {
                    return (
                      <option key={idx} value={(idx + 1) * 10}>
                        {(idx + 1) * 10}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          {data.isLoading || data.isFetching ? (
            <Spinner />
          ) : data.status === "error" ? (
            <Errors errorObject={data.error} inline />
          ) : (
            <>
              <div className=" bg-white rounded-lg shadow  relative">
                <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                  <thead>
                    <tr className="text-left">
                      <th className="bg-gray-100  sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        File Name
                      </th>
                      <th className="bg-gray-100  sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Uploaded by
                      </th>
                      <th className="bg-gray-100  sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Uploaded at
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Status
                      </th>
                      <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Download
                      </th>
                      {/* <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs text-center">
                        Action
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data.data?.data?.map((productData, idx) => {
                      const {
                        id,
                        category_code,
                        name,
                        file_name,
                        status,
                        uploaded_at,
                      } = productData || {};
                      return (
                        <tr key={idx}>
                          <td className="border-dashed border-t border-gray-200 text-center firstName">
                            <div className=" p-2 border-r text-xs ">
                              {file_name}
                            </div>
                          </td>
                          <td className="border-dashed border-t border-gray-200 text-center firstName">
                            <div className=" p-2 border-r text-xs ">{name}</div>
                          </td>
                          <td className="border-dashed border-t border-gray-200 text-center firstName">
                            <div className=" p-2 border-r text-xs ">
                              {uploaded_at}
                            </div>
                          </td>
                          <td className="border-dashed border-t border-gray-200 firstName border-r text-center">
                            <span className="text-gray-700 py-3  text-xs">
                              {status}
                            </span>
                          </td>
                          <td className="border-dashed border-t text-center justify-center items-center border-gray-200 firstName border-r">
                            <a
                              download={true}
                              target="blank"
                              href={`https://stage-api.onlinefittings.com/product_excel_upload_download?id=${id}`}
                              className="flex-1 text-blue-500 underline text-sm cursor-pointer text-center items-center"
                              // onClick={() => handleDownload(id)}
                            >
                              Download product
                            </a>
                            {/* <p
                              className="flex-1 text-blue-500 underline text-sm cursor-pointer text-center items-center"
                              onClick={() => handleDownload(id)}
                            >
                              Download product
                            </p> */}
                          </td>
                          {/* <td className="border-dashed border-t border-gray-200 emailAddress">
                            <div className="flex items-center justify-center">
                              <Link to={`edit-category/${id}?mode=edit`}>
                                <IconButton>
                                  <EditIcon />
                                </IconButton>
                              </Link>
                              <RiDeleteBinLine
                                onClick={() => handleDelete(id)}
                                className="text-lg cursor-pointer text-gray-600"
                              />
                            </div>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div>
                <Pagination
                  currentPage={+paramsObject.pageNo}
                  lengthofItems={data.data?.total_count}
                  limit={paramsObject.limit}
                  onPageChange={handlePageClick}
                />
              </div>
            </>
          )}
        </div>
      </div>

      {/* <DeleteModal
        open={isDeleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        title={"Delete Category"}
        message={"Are you sure you want to delete this category?"}
        onDelete={() => handleSubmit({ id: category })}
      /> */}
      <ConfirmationModal
        open={isModalOpen}
        handleClose={() => setModalOpen(false)}
        title="Confirm Upload"
        message={`Are you sure you want to upload the file: ${fileName}?`}
        onConfirm={handleUploadConfirm}
        confirmText="Upload"
        cancelText="Cancel"
        confirmColor="primary"
        cancelColor="secondary"
      />
    </>
  );
};

export default ProductUpload;
